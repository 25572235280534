import { FindByTypename, Maybe } from "@clubofcode/types";
import { Title, Render } from "@clubofcode/ui";
import clsx from "clsx";
import { useRouter } from "next/router";

import { usePublicCompanyProfileQuery } from "~/utils";

import { VideoEmbed } from "../VideoEmbed";
import { CompanyProfileAboutUsBenefit } from "./components";

type Props = { companyName?: Maybe<string> };

const CompanyProfileAboutUs = ({ companyName }: Props) => {
  const router = useRouter();
  const slug = router.query.slug as string | undefined;
  const { data, isLoading } = usePublicCompanyProfileQuery(slug);
  const profile = data?.getPublicCompanyProfileBySlug;
  const listableBenefits = profile?.listableBenefits;
  const extraBenefits = profile?.extraBenefits.find(
    (b) => b.__typename === "CompanyBenefitExtra",
  ) as
    | FindByTypename<
        NonNullable<
          NonNullable<typeof data>["getPublicCompanyProfileBySlug"]
        >["extraBenefits"][number],
        "CompanyBenefitExtra"
      >
    | undefined;
  const aboutUsImages = [
    profile?.firstAboutUsImage,
    profile?.secondAboutUsImage,
    profile?.thirdAboutUsImage,
  ];
  const showAboutUsImages = aboutUsImages.filter(Boolean).length === 3;

  if (isLoading) {
    return null;
  }

  return (
    <div className="space-y-16 pt-4">
      <Render
        if={
          profile?.aboutUsDescription?.length ||
          profile?.videoUrl?.length ||
          showAboutUsImages
        }
      >
        <section>
          <Title
            as="h1"
            className="text-5xl font-black tracking-tight text-center mb-6"
          >
            Über uns
          </Title>
          <Render if={profile?.videoUrl?.length}>
            <VideoEmbed
              src={profile?.videoUrl!}
              title="company video"
              cookieKey="third-party-video-consent"
            />
          </Render>
          <p className="text-xl leading-extra-relaxed whitespace-pre-wrap">
            {profile?.aboutUsDescription}
          </p>
          <Render if={showAboutUsImages}>
            <div className="grid grid-cols-4 gap-x-4 my-6 -mx-8">
              {aboutUsImages.map((image) => (
                <Render key={image?.id} if={image?.url}>
                  <div
                    className={clsx("overflow-hidden", {
                      "col-span-2 rounded-r-lg": image?.position === 1,
                      "rounded-lg": image?.position === 2,
                      "rounded-l-lg": image?.position === 3,
                    })}
                  >
                    <img
                      src={image?.url ?? undefined}
                      className="object-none object-center h-full"
                      alt="company logo"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Render>
              ))}
            </div>
          </Render>
        </section>
      </Render>
      <Render if={listableBenefits?.length || !!extraBenefits}>
        <section>
          <Title
            as="h1"
            className="text-5xl font-black tracking-tight text-center mb-6"
          >
            Benefits
          </Title>
          <div className="flex flex-wrap justify-center">
            {data?.getPublicCompanyProfileBySlug?.listableBenefits.map(
              (benefit) => {
                if (benefit.__typename !== "CompanyBenefitExtra") {
                  return (
                    <CompanyProfileAboutUsBenefit
                      // @ts-expect-error
                      key={benefit.title}
                      // @ts-expect-error
                      title={benefit.title}
                      // @ts-expect-error
                      description={benefit.description}
                      // @ts-expect-error
                      canBeClaimed={benefit.canBeClaimed}
                    />
                  );
                } else {
                  return null;
                }
              },
            )}
          </div>
          <Render if={extraBenefits?.description?.length}>
            <p className="text-xl leading-relaxed whitespace-pre-wrap mt-6">
              <b className="text-2xl">Weitere Benefits</b>
              <br />
              {extraBenefits?.description}
            </p>
          </Render>
        </section>
      </Render>
    </div>
  );
};

export default CompanyProfileAboutUs;
