import { TabList, TabPanel, Tabs } from "@clubofcode/ui";
import React, { useEffect } from "react";
import { useRouter } from "next/router";

import { CompanyProfileAboutUs } from "~/components/CompanyProfileAboutUs";
import { CompanyProfileHeader } from "~/components/CompanyProfileHeader";
import { CompanyProfileJobs } from "~/components/CompanyProfileJobs";
import { CompanyProfileLayout } from "~/components/Layout";
import { CompanyProfileTab } from "~/components/Tab";
import {
  useHasPublicCompanyProfileQuery,
  usePublicCompanyProfileQuery
} from "~/utils";

const tabs = ["Jobs", "Über das Unternehmen"];

const CompanySlugPage = () => {
  const router = useRouter();
  const slug = router.query.slug as string | undefined;
  const { data, isLoading } = usePublicCompanyProfileQuery(slug);
  const hasPublicCompanyProfileResult = useHasPublicCompanyProfileQuery(slug);
  const websiteUrl = data?.getPublicCompanyProfileBySlug?.websiteUrl;
  const isPublic = hasPublicCompanyProfileResult.data?.hasPublicCompanyProfile;

  useEffect(() => {
    if (isPublic === false) {
      router.push("/404", "/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic]);

  if (
    hasPublicCompanyProfileResult.isLoading ||
    isLoading ||
    !slug ||
    !isPublic
  ) {
    return null;
  }

  return (
    <CompanyProfileLayout
      companyName={data?.getPublicCompanyProfileBySlug?.name}
    >
      <div className="flex gap-x-4 mb-8">
        <CompanyProfileHeader
          companyName={data?.getPublicCompanyProfileBySlug?.name}
          companySize={data?.getPublicCompanyProfileBySlug?.sizeLocale}
          sector={data?.getPublicCompanyProfileBySlug?.sector?.name}
          logoFileUrl={data?.getPublicCompanyProfileBySlug?.logoFileUrl}
          bannerFileUrl={data?.getPublicCompanyProfileBySlug?.banner?.url}
          websiteUrl={websiteUrl}
        />
      </div>
      <Tabs className="mt-4">
        <TabList className="mb-2 border-b-2 border-gray-900 px-8 space-x-3">
          {tabs.map((title) => (
            <CompanyProfileTab key={title}>{title}</CompanyProfileTab>
          ))}
        </TabList>
        <TabPanel className="pt-2 px-8">
          <CompanyProfileJobs slug={slug} />
        </TabPanel>
        <TabPanel className="px-8">
          <CompanyProfileAboutUs
            companyName={data?.getPublicCompanyProfileBySlug?.name}
          />
        </TabPanel>
      </Tabs>
    </CompanyProfileLayout>
  );
};

export default CompanySlugPage;
