import React from "react";
import { Tab as ReactTab, TabProps } from "react-tabs";

type Props = Omit<TabProps, "className" | "selectedClassName">;

const CompanyProfileTab = React.forwardRef((props: Props, ref: any) => {
  return (
    <ReactTab
      {...props}
      className="relative inline-block font-medium px-6 py-1.5 cursor-pointer border border-b-0 border-gray-300 bg-gray-100 hover:bg-gray-200 hover:border-gray-400 rounded-t"
      selectedClassName="text-white bg-gray-900 border-gray-900 hover:bg-gray-900/90 hover:border-gray-900/90"
      ref={ref}
    />
  );
});

CompanyProfileTab.displayName = ReactTab.constructor.name;
// react-tabs checks nested components internally, which makes it impossible to use custom components
// based on the <Tab /> component. However, we can trick the system by setting the value below.
// See (1): https://github.com/reactjs/react-tabs/issues/148#issuecomment-335341112
// See (2): https://github.com/reactjs/react-tabs/issues/148#issuecomment-368809171
(CompanyProfileTab as any).tabsRole = "Tab";

export default CompanyProfileTab;
