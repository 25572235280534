import { Maybe } from "@clubofcode/types";
import { Render } from "@clubofcode/ui";
import clsx from "clsx";
import { CompanyProfileHeaderTitle } from "./components";

type Props = {
  logoFileUrl?: Maybe<string>;
  bannerFileUrl?: Maybe<string>;
  companyName?: Maybe<string>;
  websiteUrl?: Maybe<string>;
  sector?: Maybe<string>;
  companySize?: Maybe<string>;
};

/**
 * Renders the different states of the header of the company profile.
 */
const CompanyProfileHeader = (props: Props) => {
  if (props.bannerFileUrl) {
    return (
      <div>
        <div className="overflow-y-hidden h-[256px]">
          <img
            src={props.bannerFileUrl ?? undefined}
            className="relative object-none object-center"
            alt="banner"
            width="100%"
            height={256}
          />
        </div>
        <div
          className={clsx(
            "flex gap-x-4 relative z-1 mx-8",
            props.logoFileUrl ? "-mt-14" : "-mt-8",
          )}
        >
          <Render if={props.logoFileUrl}>
            <img
              src={props.logoFileUrl ?? undefined}
              className="relative -left-2 rounded-xl bg-white border-2 border-white"
              alt="company logo"
              width={164}
              height={164}
            />
          </Render>
          <div className="self-center mt-12">
            <CompanyProfileHeaderTitle {...props} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-x-4 px-8 mt-6">
      <Render if={props.logoFileUrl}>
        <img
          src={props.logoFileUrl ?? undefined}
          className="rounded-lg"
          alt="company logo"
          width={128}
          height={128}
        />
      </Render>
      <div className="self-center">
        <CompanyProfileHeaderTitle {...props} />
      </div>
    </div>
  );
};

export default CompanyProfileHeader;
