import { Maybe } from "@clubofcode/types";
import {
  BriefcaseIcon,
  GlobeIcon,
  Render,
  Title,
  UsersIcon
} from "@clubofcode/ui";

type Props = {
  companyName?: Maybe<string>;
  websiteUrl?: Maybe<string>;
  sector?: Maybe<string>;
  companySize?: Maybe<string>;
};

const CompanyProfileHeaderTitle = <T extends Props>(props: T) => {
  return (
    <>
      <Title as="h1" className="font-bold tracking-tight text-6xl">
        {props.companyName}
      </Title>
      <ul className="flex gap-x-3 text-lg font-medium text-gray-600 mt-1">
        <Render if={props.websiteUrl}>
          <li className="flex items-center gap-x-1">
            <GlobeIcon size={16} className="relative -top-2" />
            <a
              href={props.websiteUrl ?? undefined}
              className="text-indigo-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.websiteUrl ? new URL(props.websiteUrl).hostname : ""}
            </a>
          </li>
          <li aria-hidden="true">
            <span className="font-bold text-gray-500">&middot;</span>
          </li>
        </Render>
        <li className="flex items-center gap-x-1">
          <BriefcaseIcon size={16} className="relative -top-2" />
          {props.sector}
        </li>
        <li aria-hidden="true">
          <span className="font-bold text-gray-500">&middot;</span>
        </li>
        <li className="flex items-center gap-x-1">
          <UsersIcon size={16} className="relative -top-2" />
          {props.companySize}
        </li>
      </ul>
    </>
  );
};

export default CompanyProfileHeaderTitle;
