import { Maybe } from "@clubofcode/types";
import { BaseIconProps, CheckIcon, Render } from "@clubofcode/ui";

type Props = {
  title?: Maybe<string>;
  description?: Maybe<string>;
  canBeClaimed: boolean;
};

const CompanyProfileAboutUsBenefit = ({
  title,
  description,
  canBeClaimed
}: Props) => {
  return (
    <Render if={canBeClaimed}>
      <div className="flex gap-x-4 py-2 px-4 rounded">
        <div className="self-center align-middle">
          <div className="bg-[#18db59] rounded-lg p-2">
            <CheckIcon className="text-white" size={28} />
          </div>
        </div>
        <div>
          <b>{title}</b>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </Render>
  );
};

export default CompanyProfileAboutUsBenefit;
