import React, { useState } from "react";
import Cookies from "js-cookie";
import { BrandButton, PlayIcon } from "@clubofcode/ui";

type Props = {
  /**
   * A key for a consent cookie.
   * If provided the user must accept the cookie before the embed is loaded.
   */
  cookieKey?: string;
  src: HTMLIFrameElement["src"];
} & Omit<
  React.HTMLProps<HTMLIFrameElement>,
  "className" | "width" | "height" | "src"
>;

/**
 * Uses `js-cookie` under the hood.
 * @param {string} key Cookie name
 * @param {string} initialValue  Value will be assign if cookie doesn't exist.
 * @returns {Array} Returns cookie value, and the function to update it.
 */
// copied from apps\client\src\utils\hooks\useCookie.ts
export function useCookie(
  key?: string,
  initialValue?: string
): [typeof item, typeof setValue] {
  const [item, setInnerValue] = useState(() => {
    if (!key) return initialValue;
    return Cookies.get(key) || initialValue;
  });

  /**
   * Set value of cookie
   * @param {string} value
   * @param {Cookies.CookieAttributes} [options]
   */
  const setValue = (value: string, options?: Cookies.CookieAttributes) => {
    if (key) {
      setInnerValue(value);
      Cookies.set(key, value, options);
    }
  };

  return [item, setValue];
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="relative aspect-ratio-16/9 box-content mb-4">
      {children}
    </div>
  );
};

/**
 * Embeds a third party video with a 16:9 aspect ratio.
 * Will ask for consent if a cookie key is provided.
 */
const VideoEmbed = ({ cookieKey, ...iframeProps }: Props) => {
  const [cookie, setCookie] = useCookie(cookieKey);

  if (!cookieKey || cookie === "true") {
    return (
      <Wrapper>
        <iframe
          className="absolute top-0 left-0 h-full w-full"
          width="100%"
          height="100%"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          {...iframeProps}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="absolute top-0 left-0 h-full w-full bg-gray-200">
        <div className="h-full flex flex-col items-center justify-center text-center gap-y-4">
          <PlayIcon size={42} className="text-gray-500" />
          <p>
            Bitte akzeptieren Sie das Einbinden von Drittanbieter-Videos, um
            dieses Video ansehen zu können.
            <br />
            Dabei können Cookies durch den Drittanbieter gesetzt werden.
          </p>
          <BrandButton onClick={() => setCookie("true", { expires: 3 * 365 })}>
            Akzeptieren
          </BrandButton>
        </div>
      </div>
    </Wrapper>
  );
};

export default VideoEmbed;
