import { CloseIcon, Hotkey, Input, Render } from "@clubofcode/ui";
import { useState } from "react";
import { useSearchJobPostingsOfPublicCompanyQuery } from "~/utils";
import { CompanyProfileJobsResult } from "./components";

type Props = { slug?: string };

const CompanyProfileJobs = ({ slug }: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data } = useSearchJobPostingsOfPublicCompanyQuery({
    query: searchQuery,
    companySlug: slug
  });
  const hasResults = !!data?.searchJobPostingsOfPublicCompany?.nodes.length;
  const hasNoJobs = !hasResults && !searchQuery.length;

  if (hasNoJobs) {
    return (
      <p className="text-center my-12 font-medium text-gray-600 text-lg">
        Dieses Unternehmen hat bislang keine Stellen veröffentlicht.
      </p>
    );
  }

  return (
    <>
      {/* input for job search */}
      <div className="relative">
        <Input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          placeholder="Jobs durchsuchen..."
          className="pr-32"
          label="Suche"
          required
          block
          spellCheck={false}
        />
        {/* h-[39px]: 39px is the total height of the input */}
        <div className="h-[39px] absolute bottom-0 right-0 mr-2 select-none pointer-events-none">
          <div className="h-full flex items-center">
            {searchQuery.length > 0 && (
              <div
                className="flex-none bg-gray-500 hover:bg-gray-700 text-white p-[2px] rounded-full leading-none cursor-pointer pointer-events-auto"
                onClick={() => {
                  setSearchQuery("");
                }}
              >
                <CloseIcon size={12} strokeWidth={3} />
              </div>
            )}
            <div>
              <Hotkey keys={["Strg", "K"]} />
            </div>
          </div>
        </div>
      </div>

      <Render
        if={hasResults}
        else={
          <p className="text-center my-12 font-medium text-gray-600 text-lg">
            Keine Stellen für diese Suche gefunden.
          </p>
        }
      >
        <div className="flex flex-col gap-y-6 mt-4">
          {data?.searchJobPostingsOfPublicCompany?.nodes.map((node) => {
            if (node?.__typename === "SearchJobPostingInternal") {
              return <CompanyProfileJobsResult key={node.id} job={node} />;
            }

            return null;
          })}
        </div>
      </Render>
    </>
  );
};

export default CompanyProfileJobs;
